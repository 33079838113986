import { ButtonWithIcon } from "@geome/react-components-next/lib/components/buttonWithIcon"
import { useI18n } from "@geome/react-next/lib/hooks/i18n"
import { useRecoilValue } from "@geome/recoil"
import { activeRouteOptionsCountSelector } from "@geome/recoil/dist/feature/directions/selectors"
import { useModalState } from "@geome/recoil/dist/feature/ui/hooks"
import React, { ReactElement } from "react"
import { BPRetailLocatorModal } from "../../types"
import { RouteOptionsIcon } from "../icons/routeOptions"

export const RouteOptionsButton = (): ReactElement => {
  const { translate } = useI18n()
  const { currentModal, setCurrentModal } = useModalState<BPRetailLocatorModal>()
  const activeCount = useRecoilValue(activeRouteOptionsCountSelector)

  const count = activeCount > 0 ? activeCount : undefined
  const countDescription = count
    ? (translate("route_options.button.aria_count", { count }) as string)
    : undefined

  return (
    <ButtonWithIcon
      onClick={async () =>
        setCurrentModal(currentModal?.name === "RouteOptions" ? null : { name: "RouteOptions" })
      }
      text={translate("route_options.button.aria_text") as string}
      textPosition="tooltip"
      count={count}
      countDescription={countDescription}
      icon={<RouteOptionsIcon />}
      name="toggle-modal-button"
      className="app__sidebar-route-options-toggle-button"
    />
  )
}
