import { ButtonWithIcon } from "@geome/react-components-next/lib/components/buttonWithIcon"
import { useI18n } from "@geome/react-next/lib/hooks/i18n"
import { useUIFilters } from "@geome/recoil/dist/feature/filters/hooks"
import { useModalState } from "@geome/recoil/dist/feature/ui/hooks"
import React, { ReactElement } from "react"
import { FiltersIcon } from "../icons/filters"
import { BPRetailLocatorModal } from "../../types"

export const FiltersButton = (): ReactElement => {
  const { translate } = useI18n()
  const { currentModal, setCurrentModal } = useModalState<BPRetailLocatorModal>()
  const { activeCount } = useUIFilters()

  const count = activeCount > 0 ? activeCount : undefined
  const countDescription = count
    ? (translate("filters.button.aria_count", { count }) as string)
    : undefined

  return (
    <ButtonWithIcon
      className="app__sidebar-filter-toggle-button"
      onClick={() => {
        setCurrentModal(currentModal?.name === "Filters" ? null : { name: "Filters" })
      }}
      text={translate("filters.button.aria_text") as string}
      textPosition="tooltip"
      count={count}
      countDescription={countDescription}
      icon={<FiltersIcon />}
      name="toggle-modal-button"
    />
  )
}
