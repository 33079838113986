import { useRecoilValue } from "@geome/recoil"
import { waypointsLimitAtom } from "@geome/recoil/dist/feature/directions/atoms"
import { waypointsInfoSelector } from "@geome/recoil/dist/feature/directions/selectors"

export const useWaypointsInfo = (): { limit: number; filled: number } => {
  const waypointsInfo = useRecoilValue(waypointsInfoSelector)
  const filled = waypointsInfo.filter(({ selectionIntent }) => Boolean(selectionIntent)).length
  const limit = useRecoilValue(waypointsLimitAtom)

  return { limit, filled }
}
