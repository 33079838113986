import { GoogleMap } from "@geome/react-components-next/lib/components/map/google"
import { BlockScopingContextProvider } from "@geome/react-next/lib/context/blockScoping"

import React, { ReactElement } from "react"
import { Clusters } from "./clusters"
import { Locations } from "./locations"
import { MapOptions } from "./mapOptions"
import { MobileListButton } from "../locationsList/mobileListButton"
import { LocationTooltip } from "./locationTooltip"
import { DirectionsRoute } from "./directionsRoute"
import { MobileItineraryButton } from "../directions/mobileItineraryButton"

export const Map = (): ReactElement => (
  <BlockScopingContextProvider block="map">
    <GoogleMap>
      <DirectionsRoute />
      <Locations />
      <Clusters />
      <MapOptions />
      <MobileListButton />
      <MobileItineraryButton />
      <LocationTooltip />
    </GoogleMap>
  </BlockScopingContextProvider>
)
