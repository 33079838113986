import { DirectionsItinerary } from "@geome/react-components-next/lib/components/directionsItinerary"
import { useI18n } from "@geome/react-next/lib/hooks/i18n"
import { useRecoilValue } from "@geome/recoil"
import { directionsRouteSelector } from "@geome/recoil/dist/feature/directions/selectors"
import React, { ReactElement } from "react"
import { BasicPinIcon } from "../icons/basicPin"
import { ManeuverIcon } from "./maneuverIcon"

export const Itinerary = (): ReactElement | null => {
  const directionsRoute = useRecoilValue(directionsRouteSelector)
  const { translate } = useI18n()

  if (!directionsRoute) return null
  return (
    <DirectionsItinerary
      route={directionsRoute}
      shouldRenderLegDestination
      renderTotals={({ content }) => <div style={{ display: "flex", gap: "10px" }}>{content}</div>}
      renderStep={({ content, step }) => (
        <div style={{ display: "flex", alignItems: "center" }}>
          <div style={{ flexBasis: "30px" }}>
            <ManeuverIcon maneuver={step.maneuver} />
          </div>
          {content}
        </div>
      )}
      renderLeg={({ stepContent, leg }) => (
        <div className="itinerary__leg">
          {stepContent}
          <div className="itinerary__leg-destination">
            <BasicPinIcon />
            <span>
              {translate("directions_itinerary.leg_destination", { placeName: leg.end_address })}
            </span>
          </div>
        </div>
      )}
    />
  )
}
