import React from "react"
import { ReactElement } from "react"

export const BasicPinIcon = (): ReactElement => (
  <svg width="20" height="29" viewBox="0 0 20 29" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M10.016 1.66669C5.63446 1.66669 1.66675 5.25329 1.66675 9.67879C1.66675 14.1026 5.28657 19.3844 10.0152 26.9601C14.7437 19.3848 18.364 14.1026 18.364 9.67838C18.364 5.25329 14.3971 1.66669 10.0152 1.66669H10.016Z"
      fill="white"
      stroke="#377C21"
      strokeWidth="0.5"
    />
  </svg>
)
