import React, { ReactElement } from "react"
import { Search as GenericSearch } from "@geome/react-components-next/lib/components/search/search"
import { MagnifyingGlassIcon } from "../icons/magnifyingGlass"
import { FiltersButton } from "../filters/button"
import { RecentSearches } from "@geome/react-components-next/lib/components/search/recentSearches"
import { DirectionsButton } from "../directions/button"

export const Search = (): ReactElement => (
  <div className="search__wrapper">
    <GenericSearch
      completionProps={{
        hasGeolocation: true,
        renderPreSearchContent: (onSelect, atom) => (
          <RecentSearches renderIcon={() => null} onSelect={onSelect} selectionIntentAtom={atom} />
        ),
        captureRecentSearches: true,
      }}
      renderSearchIcon={() => <MagnifyingGlassIcon />}
      renderAfter={() => (
        <>
          <DirectionsButton />
          <FiltersButton />
        </>
      )}
    />
  </div>
)
