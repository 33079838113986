import React from "react"
import { ReactElement } from "react"

export const MagnifyingGlassIcon = (): ReactElement => (
  <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M0.746513 19.913L6.33153 14.328C7.827 15.6675 9.76453 16.4075 11.7722 16.406C16.2817 16.406 19.9504 12.7373 19.9504 8.22777C19.9504 3.7183 16.2817 0.0495605 11.7722 0.0495605C7.26274 0.0495605 3.59403 3.7183 3.59403 8.22777C3.59246 10.2355 4.33248 12.173 5.67204 13.6685L0.0870247 19.2535C-0.000429153 19.3409 -0.0495605 19.4596 -0.0495605 19.5832C-0.0495605 19.7069 -0.000429153 19.8255 0.0870247 19.913C0.174479 20.0004 0.293091 20.0496 0.416769 20.0496C0.540447 20.0496 0.65906 20.0004 0.746513 19.913ZM11.7722 0.982203C15.7675 0.982203 19.0178 4.23254 19.0178 8.22777C19.0178 12.223 15.7675 15.4733 11.7722 15.4733C7.77703 15.4733 4.52667 12.223 4.52667 8.22777C4.52667 4.23254 7.77703 0.982203 11.7722 0.982203Z"
      fill="currentColor"
    />
  </svg>
)
