import React from "react"
import { ConfigContextProvider } from "@geome/react-next/lib/context/config"
import { GoogleMapsLoaderUsingConfig } from "@geome/react-components-next/lib/components/map/google/loader"
import { config } from "../../config"
import { Resources } from "../resources"
import { I18nContextProvider } from "@geome/react-next/lib/context/i18nContext/provider"
import { AppSideBar } from "./sidebar"
import { initialiseFilterState } from "@geome/recoil/dist/feature/filters/initialisers"
import { initialiseDirectionsOptions } from "@geome/recoil/dist/feature/directions/initialisers"
import { initialiseResourcesState } from "@geome/recoil/dist/feature/resources/initialisers"
import { RecoilMultiInitRoot } from "@geome/recoil"
import { GeospatialApiRequesterContextProvider } from "@geome/geospatial-api-requester"
import { CSSCustomPropertyContextProvider } from "@geome/react-next/lib/context/cssCustomProperty"
import DistanceFormatHelper from "@geome/intl/lib/formatHelper/distance"
import { Map } from "../map"
import { UserInteractionContextProvider } from "@geome/react-next/lib/context/userInteraction"
import { BlockScopingContextProvider } from "@geome/react-next/lib/context/blockScoping"
import { setSelectionIntentFromURL } from "@geome/recoil/dist/feature/selection/initalisers/fromURL"
import { BPRetailLocatorAppConfig } from "../../types"

const App = (): JSX.Element => (
  <ConfigContextProvider value={config as BPRetailLocatorAppConfig}>
    {(finalConfig: BPRetailLocatorAppConfig) => (
      <RecoilMultiInitRoot
        initialisers={[
          initialiseFilterState(finalConfig),
          initialiseResourcesState(finalConfig),
          initialiseDirectionsOptions(finalConfig),
          setSelectionIntentFromURL,
        ]}
      >
        <UserInteractionContextProvider>
          <GeospatialApiRequesterContextProvider>
            <I18nContextProvider
              intlData={finalConfig.intlData}
              locale={finalConfig.locale}
              mode={process.env.NODE_ENV !== "prod" ? "development" : "production"}
              intlExplorer={finalConfig.intlExplorer}
              formatHelpers={[DistanceFormatHelper]}
            >
              <GoogleMapsLoaderUsingConfig>
                <CSSCustomPropertyContextProvider
                  properties={{
                    "--primary-colour": "#007F00",
                    "--text-dark-colour": "#666666",
                    "--search-input__text-colour": "#666666",
                    "--bp-grey--500": "#666666",
                    "--bp-green--500": "#007F00",
                    "--bp-green--600": "#006A00",
                    "--bp-pale-grey--500": "#EBEBEB",
                    "--bp-light-pale-grey--500": "#F7F7F7",
                    "--bp-red--500": "#FF0000",
                    "--logos-width": "90px",
                  }}
                >
                  <BlockScopingContextProvider block="app">
                    <div className="app">
                      <AppSideBar />
                      <Map />
                      <Resources />
                    </div>
                  </BlockScopingContextProvider>
                </CSSCustomPropertyContextProvider>
              </GoogleMapsLoaderUsingConfig>
            </I18nContextProvider>
          </GeospatialApiRequesterContextProvider>
        </UserInteractionContextProvider>
      </RecoilMultiInitRoot>
    )}
  </ConfigContextProvider>
)

export { App }
