import {
  useIsShowingMapClusters,
  useLocations,
  useSelectedLocation,
} from "@geome/recoil/dist/feature/locations/hooks"
import { BPRetailLocation, BPRetailLocatorModal } from "../../types"
import { useMobileMapListState } from "@geome/recoil/dist/feature/ui/hooks"
import { useModalState } from "@geome/recoil/dist/feature/ui/hooks"
import { useRecoilState, useRecoilValue } from "@geome/recoil"
import { appModeAtom, directionsViewAtom } from "../../recoil/atoms"
import { useIsMobile } from "../../hooks/useIsMobile"
import { useEffect } from "react"
import { directionsRouteSelector } from "@geome/recoil/dist/feature/directions/selectors"

export const useViewSwitcher = (): {
  showList: boolean
  showInfoWindow: boolean
  showFilters: boolean
  showItinerary: boolean
  showRouteOptions: boolean
} => {
  const appMode = useRecoilValue(appModeAtom)
  const [directionsView, setDirectionsView] = useRecoilState(directionsViewAtom)
  const { mobileView } = useMobileMapListState()
  const listLocations = useLocations({ resource: "locations", endpoint: "nearest_to" })
  const { currentModal } = useModalState<BPRetailLocatorModal>()
  const selectedLocation = useSelectedLocation<BPRetailLocation>()
  const isShowingClusters = useIsShowingMapClusters({ resource: "locations" })
  const directionsRoute = useRecoilValue(directionsRouteSelector)
  const isMobile = useIsMobile()

  useEffect(() => {
    if (!isMobile && directionsView === "map") setDirectionsView("itinerary")
  }, [isMobile, directionsView, setDirectionsView])

  let showList = false
  const showInfoWindow = selectedLocation !== null
  const showFilters = currentModal?.name === "Filters"
  const showRouteOptions = currentModal?.name === "RouteOptions"
  let showItinerary = false

  if (appMode === "search") {
    showList =
      (!isMobile || mobileView === "list") && !isShowingClusters && listLocations.length > 0
  }

  if (appMode === "directions") {
    showItinerary = Boolean(directionsView === "itinerary" && directionsRoute)
  }

  return {
    showList,
    showInfoWindow,
    showFilters,
    showItinerary,
    showRouteOptions,
  }
}
