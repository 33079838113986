import React, { ReactElement } from "react"
import { LocationsList as LocationsListBase } from "@geome/react-components-next/lib/components/locationsList"
import { LocationsListItem } from "@geome/react-components-next/lib/components/locationsList/item"
import { BlockScopingContextProvider } from "@geome/react-next/lib/context/blockScoping"
import { ResourceEndpoint } from "@geome/types"
import { OpenNow } from "@geome/react-components-next/lib/components/openNow"
import { BPRetailLocation } from "../../types"
import { useI18n } from "@geome/react-next"

export const LocationsList = ({
  endpoint = "nearest_to",
}: {
  endpoint?: ResourceEndpoint
}): ReactElement => {
  const { translate } = useI18n()

  return (
    <BlockScopingContextProvider block="locations-list">
      <LocationsListBase endpoint={endpoint} resource="locations">
        {(location: BPRetailLocation) => {
          const {
            name,
            formatted_address,
            telephone,
            driving_distance,
            distance,
            open_status,
            next_open_status_change,
          } = location

          const displayDistance = driving_distance ?? distance ?? null

          return (
            <LocationsListItem
              key={location.id}
              isSplitButton
              resource="locations"
              location={location}
              renderContent={(_, props) => (
                <div className="locations-list__container" {...props} tabIndex={0} role="button">
                  <div className="locations-list__item-info-name">{name}</div>
                  <div className="locations-list__content">
                    <div className="locations-list__item-info">
                      <div className="locations-list__address">{formatted_address}</div>
                      <div className="locations-list__telephone">{telephone}</div>
                      {displayDistance && (
                        <div className="locations-list__distance">
                          {translate("distance", {
                            convert: true,
                            distance: displayDistance,
                          })}
                        </div>
                      )}
                    </div>
                    <div className="locations-list__item-open-now">
                      {open_status && (
                        <OpenNow
                          openStatus={open_status}
                          nextOpenStatusChange={next_open_status_change || null}
                          configPath=""
                        />
                      )}
                    </div>
                  </div>
                </div>
              )}
            />
          )
        }}
      </LocationsListBase>
    </BlockScopingContextProvider>
  )
}
