import { ButtonWithIcon } from "@geome/react-components-next/lib/components/buttonWithIcon"
import { useI18n } from "@geome/react-next/lib/hooks/i18n"
import React, { ReactElement } from "react"
import { useChangeAppMode } from "../../hooks/useChangeAppMode"
import { DirectionsIcon } from "../icons/directions"
import { useSelectedPlaceAsDestination } from "@geome/recoil/dist/feature/directions/hooks"
import { usePublishUserInteraction } from "@geome/react-next"

export const DirectionsButton = (): ReactElement => {
  const { translate } = useI18n()
  const changeAppMode = useChangeAppMode()
  const setDirectionsToPlace = useSelectedPlaceAsDestination()
  const [publishInteraction] = usePublishUserInteraction("directions-link", "click")

  return (
    <ButtonWithIcon
      className="app__sidebar-filter-toggle-button"
      onClick={() => {
        setDirectionsToPlace()
        changeAppMode("directions")
        publishInteraction()
      }}
      text={translate("directions.button.aria_text") as string}
      textPosition="tooltip"
      icon={<DirectionsIcon />}
      name="toggle-directions-button"
    />
  )
}
