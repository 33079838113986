import React, { ReactElement } from "react"

export const ExternalLinkIcon = ({ className }: { className?: string }): ReactElement => (
  <svg
    width="16"
    height="16"
    viewBox="0 0 12 12"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    className={className}
  >
    <path
      d="M3.475 10.25C3.275 10.25 3.10417 10.1792 2.9625 10.0375C2.82083 9.89583 2.75 9.725 2.75 9.525V2.475C2.75 2.275 2.82083 2.10417 2.9625 1.9625C3.10417 1.82083 3.275 1.75 3.475 1.75H6.6125V2.3125H3.475C3.43333 2.3125 3.39583 2.32917 3.3625 2.3625C3.32917 2.39583 3.3125 2.43333 3.3125 2.475V9.525C3.3125 9.56667 3.32917 9.60417 3.3625 9.6375C3.39583 9.67083 3.43333 9.6875 3.475 9.6875H10.525C10.5667 9.6875 10.6042 9.67083 10.6375 9.6375C10.6708 9.60417 10.6875 9.56667 10.6875 9.525V6.3875H11.25V9.525C11.25 9.725 11.1792 9.89583 11.0375 10.0375C10.8958 10.1792 10.725 10.25 10.525 10.25H3.475ZM5.8 7.6L5.4 7.2L10.2875 2.3125H7.625V1.75H11.25V5.375H10.6875V2.725L5.8 7.6Z"
      fill="#666666"
    />
  </svg>
)
