import React, { ReactElement, Suspense } from "react"
import useResizeObserver from "use-resize-observer"

import { useSetUIPadding } from "@geome/recoil/dist/feature/map/hooks"
import { useBEM } from "@geome/react-next/lib/hooks/useBEM"
import { useIsMobile } from "../../hooks/useIsMobile"
import { Search } from "../search"
import { LocationsList } from "../locationsList"
import { useViewSwitcher } from "./useViewSwitcher"
import { InfoWindow } from "../infoWindow"
import { Filters } from "../filters"
import { BPRetailLocatorModal } from "../../types"
import { useModalState } from "@geome/recoil/dist/feature/ui/hooks"
import { DirectionsSearch } from "../directions"
import { useRecoilValue } from "@geome/recoil"
import { appModeAtom } from "../../recoil/atoms"
import { Itinerary } from "../directions/itinerary"
import { RouteOptions } from "../routeOptions"

export const AppSideBar = (): ReactElement => {
  const setUIPadding = useSetUIPadding()
  const isMobile = useIsMobile()

  const appMode = useRecoilValue(appModeAtom)
  const { showList, showInfoWindow, showFilters, showItinerary, showRouteOptions } =
    useViewSwitcher()

  const { ref } = useResizeObserver<HTMLDivElement>({
    onResize: (size) => {
      if (isMobile) {
        setUIPadding({ padding: { top: 0, left: 0, right: 0, bottom: 0 }, resizeMap: false })
        return
      }
      const padding = { top: 0, left: size.width || 0, right: 0, bottom: 0 }
      const resizeMap = (size.height || 0) > window.innerHeight * 0.8
      setUIPadding({ padding, resizeMap })
    },
  })

  const { currentModal } = useModalState<BPRetailLocatorModal>()

  // Can replace `has-content` with :has(.app__sidebar-content:not(:empty) once that's a thing
  const className = useBEM({
    block: "app",
    element: "sidebar",
    modifiers: {
      mobile: isMobile,
      "has-content": showFilters || showInfoWindow || showList || showItinerary || showRouteOptions,
      "show-list": showList && !showInfoWindow && !showFilters,
      "show-itinerary": showItinerary && !showInfoWindow && !showFilters,
    },
  })

  return (
    <div className={className} ref={ref}>
      {appMode === "search" ? <Search /> : <DirectionsSearch />}
      <div className="app__sidebar-content">
        {showList && <LocationsList />}
        {showItinerary && <Itinerary />}
        {showInfoWindow && (
          <Suspense fallback={<div className="info-window"></div>}>
            <InfoWindow />
          </Suspense>
        )}

        {currentModal?.name === "Filters" && <Filters />}
        {showRouteOptions && <RouteOptions />}
      </div>
    </div>
  )
}
