import React, { ReactElement } from "react"
import { BPRetailLocation } from "../../types"
import { SectionWithTitle } from "@geome/react-components-next/lib/components/sectionWithTitle"
import { useI18n } from "@geome/react-next"

export const FuelPricing = ({
  pricing,
}: {
  pricing: Required<BPRetailLocation>["fuel_pricing"]
}): ReactElement => {
  const { translate } = useI18n()
  return (
    <SectionWithTitle id="fuels" sectionTokenPath="info_window.sections.fuels">
      <ul className="info-window__fuel-price-list">
        {Object.entries(pricing.prices).map(([name, price]) => (
          <li className="info-window__fuel-price-list-item">
            <div className="info-window__fuel-price-name">{name}</div>
            <div
              className="info-window__fuel-price-price"
              dangerouslySetInnerHTML={{ __html: price }}
            ></div>
          </li>
        ))}
      </ul>
      <div className="info-window__fuel-price-last-updated">
        {translate("info_window.sections.fuels.last_updated", { date: pricing.last_update })}
      </div>
    </SectionWithTitle>
  )
}
