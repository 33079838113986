import { useLocations } from "@geome/recoil/dist/feature/locations/hooks"
import React, { ReactElement, Suspense } from "react"
import { BPRetailMarker } from "@geome/markers/lib/client/bp/retail/marker"
import { useRecoilValue } from "@geome/recoil"
import { directionsRouteSelector } from "@geome/recoil/dist/feature/directions/selectors"
import { BPRetailLocation } from "../../types"

export const Locations = (): ReactElement => {
  const route = useRecoilValue(directionsRouteSelector)

  const locations = useLocations<BPRetailLocation>({
    resource: "locations",
    endpoint: "within_bounds",
  })

  const alongCorridorLocations = useLocations<BPRetailLocation>({
    resource: "locations",
    endpoint: "along_corridor",
  })

  return (
    <Suspense>
      {(route ? alongCorridorLocations : locations).map((location) => (
        <BPRetailMarker key={location.id} location={location} />
      ))}
    </Suspense>
  )
}
