import { atom } from "@geome/recoil"
import { AppModes } from "../../types"

export const appModeAtom = atom<AppModes>({ key: "appModeAtom", default: "search" })

export const mobileShowMapAtom = atom<boolean>({ key: "mobileShowMapAtom", default: false })

export const directionsViewAtom = atom<"map" | "itinerary">({
  key: "directionsViewAtom",
  default: "itinerary",
})
