import React, { ReactElement } from "react"
import { WaypointsSearch } from "@geome/react-components-next/lib/components/search/waypoints"
import { MagnifyingGlassIcon } from "../icons/magnifyingGlass"
import { RecentSearches } from "@geome/react-components-next/lib/components/search/recentSearches"
import { CSSCustomPropertyContextProvider } from "@geome/react-next/lib/context/cssCustomProperty"
import { BlockScopingContextProvider } from "@geome/react-next/lib/context/blockScoping"
import { ChevronIcon } from "@geome/react-components-next/lib/components/icons/chevron"
import { useI18n } from "@geome/react-next/lib/hooks/i18n"
import { useChangeAppMode } from "../../hooks/useChangeAppMode"
import { usePublishUserInteraction } from "@geome/react-next/lib/hooks/userInteraction"
import { BPRetailCompletion } from "../../types"
import { FiltersButton } from "../filters/button"
import { RouteOptionsButton } from "../routeOptions/button"

export const DirectionsSearch = (): ReactElement => {
  const setAppMode = useChangeAppMode()
  const [publishSearchClick] = usePublishUserInteraction("search-button", "click")
  const { translate } = useI18n()

  return (
    <BlockScopingContextProvider block="directions-search">
      <CSSCustomPropertyContextProvider properties={{ "--error-colour": "#FF0000" }}>
        <div className="search__wrapper">
          <button
            className="search__back-to-search-button"
            onClick={() => {
              setAppMode("search")
              publishSearchClick()
            }}
          >
            <ChevronIcon strokeWidth={3} direction="left" />
            {translate("directions.buttons.back_to_search")}
          </button>
          <WaypointsSearch
            completionProps={{
              hasGeolocation: true,
              captureRecentSearches: false,
              renderPreSearchContent: (onSelect, atom) => (
                <RecentSearches
                  renderIcon={() => null}
                  onSelect={onSelect}
                  selectionIntentAtom={atom}
                />
              ),
            }}
            renderCompletions={{
              completions: {
                google: ({ description }) => <span>{description}</span>,
                locations: (location: BPRetailCompletion) => <span>{location.value}</span>,
              },
            }}
            renderSearchIcon={() => <MagnifyingGlassIcon />}
          />
          <div className="directions-buttons">
            <RouteOptionsButton />
            <FiltersButton />
          </div>
        </div>
      </CSSCustomPropertyContextProvider>
    </BlockScopingContextProvider>
  )
}
