import { useDirectionsToLocation } from "@geome/recoil/dist/feature/directions/hooks"
import React from "react"
import { ReactElement } from "react"
import { BPRetailLocation } from "../../types"
import { useRecoilValue } from "@geome/recoil"
import { useI18n } from "@geome/react-next"
import { appModeAtom } from "../../recoil/atoms"
import { useChangeAppMode } from "../../hooks/useChangeAppMode"
import { directionsRouteSelector } from "@geome/recoil/dist/feature/directions/selectors"
import { useWaypointsInfo } from "../../hooks/useWaypointsInfo"

export const DirectionsButton = ({ location }: { location: BPRetailLocation }): ReactElement => {
  const { translate } = useI18n()
  const appMode = useRecoilValue(appModeAtom)
  const changeAppMode = useChangeAppMode()
  const directionsRoute = useRecoilValue(directionsRouteSelector)
  const { limit, filled } = useWaypointsInfo()
  const disabled = limit === filled
  const type = directionsRoute ? "add_to_route" : "get_directions_to"

  const getDirectionsToLocation = useDirectionsToLocation()

  const onClick = (): void => {
    getDirectionsToLocation(location)
    if (appMode !== "directions") changeAppMode("directions")
  }

  return (
    <button
      className={`info-window__link-button info-window__link-button--${disabled ? "disabled" : ""}`}
      disabled={disabled}
      onClick={onClick}
    >
      {translate(`directions.${type}`)}
    </button>
  )
}
